.empty {
  clear: both;
}

.clearfix {
  clear: both;
}

.divide {

}

.text-center {
  text-align: center;
}

.ggp-bulletPoints {
  border-top-color: rgb(0, 0, 0);
  border-top-width: 1px;
  border-top-style: dotted;
}

.ggp-wrap .padding0 {
  padding: 0px;
}

.ggp-wrap .paddingLeft0 {
  padding-left: 0px;
}

.ggp-wrap .paddingRight0 {
  padding-right: 0px;
}

.HomePageJoboffers li {
  padding-bottom: 0px;
  font-size: 0.9em;
}

.HomePageJoboffers li .posizione {
  font-weight: 600;
}

.offerteHomePage li .posizione {
  font-weight: 600;
}

.home .bluebox-accordion .accordion-content a {
  text-transform: uppercase;
}

.ggp-wrap div {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.ggp-wrap input {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.ggp-wrap select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.ggp-wrap form {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.nResults {
  text-align: right;
  margin-bottom: 10px;
}

.ApplyButtons .apply_Apply_Button {
  text-align: center;
}

.ApplyButtons .apply_Back_Button {
  margin-top: -30px;
}

.verticalSearchForm {
  border-width: 10px 1px 1px;
  border-style: solid;
  border-color: rgb(150, 184, 14) rgb(234, 234, 234) rgb(234, 234, 234);
  padding: 14px;
  border-image: none;
  width: 100%;
  background-color: rgb(249, 249, 249);
}

.verticalSearchForm form select {
  width: 100%;
  margin-bottom: 4px;
  min-height: 45px;
}

.verticalSearchForm form .divide {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.verticalSearchForm_title {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.ggp-row {
  margin: 0px;
  padding: 0px;
  width: 100%;
  font-size: 1em;
  display: block;
}

.ggp-row a.columnJoboffers h1 {
  margin: 0px;
  padding: 0px;
  color: rgb(0, 94, 131);
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 700;
}

.ggp-row a.columnJoboffers h1.expired {
  color: #191919;
}

.ggp-row a.columnJoboffers h2 {
  margin: 0px;
  padding: 0px;
  line-height: 120%;
  font-size: 1em;
  display: inline;
}

.ggp-row a.columnJoboffers h3 {
  margin: 0px;
  padding: 0px;
  line-height: 120%;
  font-size: 1em;
  display: inline;
}

.ggp-row a.columnJoboffers h4 {
  margin: 0px;
  padding: 0px;
  line-height: 120%;
  font-size: 1em;
  display: inline;
}

.workRow {
  padding: 10px 4px 14px;
  transition: 0.4s;
  border-top-color: rgb(0, 0, 0);
  border-top-width: 1px;
  border-top-style: dotted;
  background-color: rgb(255, 255, 255);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

.workRow:hover {
  transition: 0.4s;
  //background-color: rgb(244, 244, 244);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

.workCol1 {
  margin: 0px;
  padding: 0px;
  width: 30%;
  float: left;
}

.workCol2 {
  margin: 0px;
  padding: 0px;
  width: 68%;
  float: left;
}

#tabSearchResult a {
  color: rgb(0, 94, 131);
}

.JobofferDetail h1 {
  color: rgb(0, 94, 131);
  text-transform: uppercase;
  line-height: 26px;
  font-size: 28px;
}

.JobofferDetail h2 {
  text-transform: uppercase;
  line-height: 22px;
  font-size: 20px;
  margin-bottom: 4px;
}

.JobofferDetail h3 {
  margin: 0px 0px 4px;
  padding: 0px;
  text-transform: uppercase;
  line-height: 22px;
  font-size: 18px;
}

.JobofferDetail h4 {
  margin: 0px 0px 4px;
  padding: 0px;
  text-transform: uppercase;
  line-height: 22px;
  font-size: 18px;
}

.JobofferDetail strong {
  text-transform: uppercase;
  font-weight: bold;
}

.ApplyRow {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-width: 1px;
  border-bottom-style: dotted;
}

.ApplyRow .addthis_toolbox {
  padding: 10px 0px;
}

.legal {
  margin: 20px 0px 60px;
  color: rgb(143, 143, 143);
  line-height: 120%;
  font-size: 12px;
}

.ApplyButtons {
  margin-top: 20px;
}

.ApplyButtons div {
  margin: 0px;
  padding: 0px;
}

div a.TurnBackButton {
  background: rgb(199, 198, 198);
  padding: 7px 16px;
  margin-top: 14px;
  cursor: pointer;
}

a.TurnBackButton:hover {
  background: rgb(220, 219, 219);
}

.ApplyButton button {
  background: rgb(0, 94, 131);
  margin: 6px 0px 0px;
  padding: 8px 20px;
  border-radius: 0px;
  border: currentColor;
  border-image: none;
  color: rgb(255, 255, 255);
  font-weight: 600;
  box-shadow: none;
}

div.ApplyButton {
  padding: 0px;
}

div.ApplyButton button {
  margin: 0px;
  padding: 12px 24px;
  font-size: 1.2em;
  font-weight: 600;
}

.branchNew_Row {
  padding: 24px 0px;
  color: rgb(102, 102, 102);
  line-height: 150%;
  border-bottom-color: rgb(0, 0, 0);
  border-bottom-width: 1px;
  border-bottom-style: dotted;
}

.branchNew_Title {
  color: rgb(51, 51, 51);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
}

.branchNew_JobofferButton {
  background: rgb(0, 94, 131);
  padding: 3px;
  width: 100%;
  text-align: right;
}

.branchNew_JobofferButton:hover {
  background: rgb(41, 128, 185);
}

.branchNew_JobofferButton a {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.branchNew_JobofferButton a:link {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.branchNew_BoxAccreditedBranch {
  background: rgb(150, 184, 14);
  padding: 0px 2px;
  border-radius: 3px;
  width: 130px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 11px;
  margin-top: 8px;
  -moz-border-radius: 3px;
}

.branchNew_BoxBranchOnSite {
  background: rgb(150, 184, 14);
  padding: 0px 2px;
  border-radius: 3px;
  width: 130px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 11px;
  margin-top: 8px;
  -moz-border-radius: 3px;
}

.branchNew_BoxBranchOnSite {
  background: rgb(140, 194, 213);
}

.ggp-wrap #tabSearchResult li {
  list-style-position: inside;
}

.ggp-wrap .workRowInfoWindow {
  padding: 8px 0px;
  line-height: 120%;
  font-size: 12px;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
}

.ggp-wrap .workRowInfoWindow a {
  color: rgb(0, 94, 131);
}

.ggp-wrap .buttonMap {
  padding: 6px 14px;
  border-radius: 3px;
  border: currentColor;
  transition: 0.4s;
  border-image: none;
  text-align: right;
  color: rgb(255, 255, 255);
  font-size: 12px;
  margin-bottom: 20px;
  opacity: 1;
  box-shadow: none;
  background-color: rgb(0, 94, 131);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

.ggp-wrap .buttonMap:hover {
  transition: 0.4s;
  opacity: 0.7;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

.AreaTitleJoinOurTeam {
  margin: 70px 0px 12px;
  text-transform: uppercase;
  padding-bottom: 12px;
  font-size: 22px;
  font-weight: 700;
}

.resultSendEmailUser {
  margin: 0px auto;
  width: 95%;
  text-align: center;
}

.joinOurTeam_searchBox {
  padding: 20px;
  border-radius: 0px;
  border: 1px solid rgb(221, 221, 221);
  border-image: none;
  width: 95%;
  box-shadow: none;
  background-color: rgb(248, 248, 248);
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.joinOurTeam_searchBox h4 {
  margin: 10px 0px 10px 20px;
}

.joinOurTeam_searchBox div select {
  padding: 0px 4px;
  width: 100%;
  min-height: 36px;
}

.joinOurTeam_searchBox button.btn {
  background: rgb(0, 94, 131);
  padding: 8px 8px 8px 50px;
  border-radius: 0px;
  border: currentColor;
  border-image: none;
  color: rgb(255, 255, 255);
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
}

.errorMessage {
  color: rgb(150, 184, 14);
}

.VisibleTitleSmall {
  color: rgb(0, 94, 131);
  font-size: 16px;
  font-weight: bold;
}

.FormApply_Sx {
  padding: 5px 0px;
  width: 25%;
  float: left;
}

.FormApply_Dx {
  padding: 5px 0px;
  width: 75%;
  float: left;
}

.formEmailUser input[type='text'] {
  border: 1px solid rgb(204, 204, 204);
  border-image: none;
  width: 85%;
  min-width: 60%;
}

@media only screen and (max-width: 767px) {
  .branchNew_JobofferButton {
    width: 200px;
    margin-top: 16px;
  }
  .ApplyButtons .apply_Apply_Button {
    text-align: center;
  }
  .ApplyButtons .apply_Back_Button {
    text-align: center;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 470px) {
  .joinOurTeam_searchBox {
    width: 88%;
  }
}

.expired {
  color: #191919;
}